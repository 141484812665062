<template>
  <div class="container">
    <el-collapse v-model="activeNames" v-if="option.isSearch">
      <el-collapse-item name="1">
        <div class="policy-form-box">
          <el-form
            :inline="true"
            :model="searchData"
            v-if="option.searchList && option.searchList.length"
            class="demo-form-inline"
            label-width="88px"
          >
            <el-form-item
              :label="item.label"
              v-for="(item, index) in option.searchList.filter(
                (val) => val.isPermissions !== false && val.isShow !== false
              )"
              :key="index"
              :label-width="item.labelWidth ? item.labelWidth : '88px'"
            >
              <el-input
                v-if="item.formType == 'input'"
                v-model.trim="searchData['search' + index]"
                :clearable="item.clearable"
                :placeholder="item.placeholder || '请输入'"
              ></el-input>
              <div
                v-else-if="item.formType == 'range-input'"
                style="display: flex"
              >
                <slot :name="item.prop" />
              </div>
              <el-select
                v-else-if="item.formType == 'select'"
                v-model="searchData['search' + index]"
                :clearable="item.clearable"
                :filterable="item.filterable"
                :disabled="item.disabled"
                :placeholder="item.placeholder || '请选择'"
              >
                <el-option
                  v-for="(status, index) in item.selectList"
                  :key="index"
                  :label="status.text"
                  :value="status.value"
                ></el-option>
              </el-select>

              <el-date-picker
                type="daterange"
                v-else-if="item.formType == 'daterange'"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :format="item.format || 'yyyy 年 MM 月 dd 日'"
                :value-format="item.valueFormat || 'yyyy-MM-dd'"
                v-model="searchData['search' + index]"
                :picker-options="item.pickerOptions"
                style="width: 100%"
              ></el-date-picker>

              <el-date-picker
                type="datetimerange"
                v-else-if="item.formType == 'datetimerange'"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :format="item.format || 'yyyy-MM-dd HH:mm:ss'"
                :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
                v-model="searchData['search' + index]"
                :picker-options="item.pickerOptions"
                :default-time="['00:00:00', '23:59:59']"
                style="width: 100%"
              ></el-date-picker>
              <v-distpicker
                class="dist-picker"
                v-else-if="item.formType == 'region'"
                :province="regionProvince"
                :city="regionCity"
                hide-area
                @selected="onSelected"
              ></v-distpicker>
              <el-date-picker
                v-else-if="item.formType == 'date'"
                v-model="searchData['search' + index]"
                type="date"
                :placeholder="item.placeholder || '请选择'"
              >
              </el-date-picker>
              <el-date-picker
                v-else-if="item.formType == 'monthDate'"
                v-model="searchData['search' + index]"
                type="month" value-format="yyyy-MM"
                :placeholder="item.placeholder || '请选择'"
              >
              </el-date-picker>
              <el-cascader v-else-if="item.formType == 'cascader'" v-model="searchData['search' + index]"
                :options="item.selectList" :placeholder="item.placeholder || '请选择'"
                :props="item.formProps"
                :collapse-tags="item.collapseTags || false"
                :clearable="item.clearable || false"
                :filterable="item.filterable || false"
                :show-all-levels="item.showAllLevels === undefined ? true : item.showAllLevels" >
              </el-cascader>
            </el-form-item>
          </el-form>
          <div style="display: flex; padding-bottom: 24px; padding-left: 12px">
            <el-button
              type="primary"
              size="small"
              @click="queryData('top')"
              icon="el-icon-circle-check"
              >提 交</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="onReset"
              plain
              icon="el-icon-refresh"
              >重 置</el-button
            >
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <slot name="statistics" />
    <div
      class="policy-table"
      :style="{ marginTop: option.isSearch ? '12px' : '0' }"
      ref="claim-table"
    >
      <div class="table-button" v-if="option.isOperate !== false">
        <div style="display: flex; align-items: center">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            size="small"
            v-if="option.isRefresh"
            plain
            @click="getList('refresh')"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-circle-plus-outline"
            v-if="option.isAdd"
            size="small"
            @click.native.prevent="handleEmitChange('add-change')"
            >添加</el-button
          >
          <el-button
            type="danger"
            size="small"
            v-if="option.isDel"
            icon="el-icon-circle-close"
            @click="handleEmitChange('del-change', tableSelectionList)"
            >删除</el-button
          >
          <!-- <el-button type="primary" size="small" icon="el-icon-search">导入</el-button> -->
          <div
            class="el-button el-button--primary el-button--small"
            @click="handleImportClick"
            v-if="option.isImport"
          >
            <img
              src="@/assets/images/policy/Import-img.png"
              style="
                width: 16px;
                height: 16px;
                margin-top: -2px;
                margin-right: 5px;
              "
              alt
              srcset
            />
            导入
          </div>
          <slot name="operate-left" />
        </div>
        <div class="table-button-right">
          <el-input
            placeholder="请输入内容"
            style="margin-right: 10px"
            v-if="
              (option.isSearch || option.isKeyword) && !option.disFuzzySearch
            "
            v-model="searchData.keyword"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="queryData('like')"
            ></el-button>
          </el-input>
          <el-popover placement="bottom" width="200" trigger="click" v-if="option.isTable !== false">
            <div
              style="max-height: 240px; overflow: auto"
              @click="handleBubbling"
            >
              <div
                v-for="(item, index) in columnData"
                style="
                  margin-top: 16px;
                  margin-right: 2px;
                  display: flex;
                  justify-content: space-between;
                "
                :key="item.prop"
              >
                <el-checkbox v-model="item.isShow" fill="#0080FF">
                  {{ item.label }}
                </el-checkbox>
                <div
                  v-if="
                    !(item.type == 'index' || item.prop == 'operate') &&
                    !item.isNoun
                  "
                >
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'caret-c': true,
                      'caret-c-d':
                        columnData[index - 1] &&
                        (columnData[index - 1].type == 'index' ||
                          columnData[index - 1].prop == 'operate' ||
                          columnData[index - 1].isNoun),
                    }"
                    :data-key="item.prop"
                    :data-index="index"
                    data-type="top"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'caret-c': true,
                      'caret-c-d':
                        columnData[index + 1] &&
                        (columnData[index + 1].type == 'index' ||
                          columnData[index + 1].prop == 'operate' ||
                          columnData[index + 1].isNoun),
                    }"
                    :data-key="item.prop"
                    :data-index="index"
                    data-type="dowm"
                  ></i>
                </div>
              </div>
            </div>
            <div
              style="
                padding-top: 8px;
                box-shadow: 0px -1px 6px 1px rgba(90, 90, 90, 0.16);
                margin: 6px -12px -12px;
                padding: 8px 12px 6px;
                display: flex;
                justify-content: space-around;
              "
            >
              <el-button @click="handleRestoreColumnData">恢复默认</el-button>
              <el-button
                type="primary"
                icon="el-icon-circle-check"
                style="background: #4278c9"
                @click="updateDiyRespList"
                >确认</el-button
              >
            </div>
            <!-- <el-button
              slot="reference"
              type="primary"
              icon="el-icon-menu"
              size="small"
              plain
            ></el-button>-->
            <!-- <div
              class="el-button el-button--primary el-button--small"
              style="background-color: #fff; padding: 5px 10px"
              slot="reference"
            >-->
            <img
              src="@/assets/images/policy/Column-control.png"
              slot="reference"
              style="
                width: 32px;
                height: 32px;
                margin-bottom: -2px;
                cursor: pointer;
              "
              alt
              srcset
              v-if="!option.isHideReference"
            />
            <!-- </div> -->
          </el-popover>
          <div style="width: 10px"></div>
          <div
            class="el-button el-button--primary el-button--small"
            style="
              background-color: #fff;
              padding: 0 5px;
              width: 32px;
              height: 32px;
            "
            @click="
              activeNames.length ? (activeNames = []) : (activeNames = ['1'])
            "
            v-if="!option.isHideActiveNames"
          >
            <img
              src="@/assets/images/policy/Column-Search.png"
              slot="reference"
              style="width: 22px"
              alt
              srcset
            />
          </div>
          <div
            class="el-button el-button--primary el-button--small"
            v-if="option.isExport"
            @click="handleExportEmit('export-data', tableSelectionList)"
          >
            <img
              src="@/assets/images/policy/Export.png"
              style="
                width: 16px;
                height: 16px;
                margin-top: -2px;
                margin-right: 5px;
              "
              alt
              srcset
            />
            导出
          </div>
          <img
            src="@/assets/images/partsRetrieval/showTableIcon.png"
            v-if="option.changeView && changeView"
            @click="handleChangeView"
            style="
              background-color: #fff;
              padding: 0 5px;
              width: 32px;
              height: 32px;
            "
            alt
            srcset
          />
          <img
            src="@/assets/images/partsRetrieval/showCardIcon.png"
            v-if="option.changeView && !changeView"
            @click="handleChangeView"
            style="
              background-color: #fff;
              padding: 0 5px;
              width: 32px;
              height: 32px;
            "
            alt
            srcset
          />
          <!-- <el-button
            slot="reference"
            type="primary"
            icon="el-icon-search"
            size="small"
            plain
            @click="
              activeNames.length ? (activeNames = []) : (activeNames = ['1'])
            "
            style="margin-left: 5px"
          >
          </el-button>-->
          <slot name="operate-right" />
        </div>
      </div>
      <div v-if="option.isTable !== false" class="content-box">
        <el-table
          :data="dataList" :stripe="option.isStripe"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange" :height="option.tableHeight" ref="tableBox"
          v-if="isChangeColumn && !changeView"
          style="width: calc(100% - 24px);"
        >
          <el-table-column
            fixed="left"
            v-if="option.isSelection !== false"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            :type="item.type"
            :label="item.label"
            :fixed="item.fixed"
            :prop="item.prop"
            :width="item.width"
            :align="item.align"
            :sortable="item.sortable"
            :show-overflow-tooltip="item['show-overflow-tooltip']"
            el-table-column
            v-for="item in columnList"
            :key="item.prop"
          >
            <template slot-scope="scope">
              <slot
                :item="JSON.parse(JSON.stringify(scope.row))"
                :name="item.prop"
                v-if="item.isSlot"
              />
              <div v-else>
                {{
                  item.type == "index"
                    ? scope.$index + 1
                    : scope.row[item.prop] === undefined ||
                      scope.row[item.prop] === "" ||
                      scope.row[item.prop] === null
                    ? option.rowPlaceholder || item.rowPlaceholder || "--"
                    : scope.row[item.prop]
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <slot v-else name="slot-view"></slot>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-if="option.isPagination !== false"
          :current-page="searchData.pageNum"
          :page-size="searchData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNum"
        ></el-pagination>
      </div>
      <div v-else>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import { mapState, mapMutations } from "vuex";
import { updateTitleDiy } from "@/api/getData.js";
import dayjs from "dayjs";
export default {
  components: { VDistpicker },
  props: {
    column: {
      // 需要显示的列表数据
      type: Array,
      default: () => [],
      // [
      //   {
      //     label: '序号',  //列表头
      //     prop: 'a',  // 字段名
      //     type: 'index', // 列表类型
      //     isShow: true, // 是否默认显示
      //     fixed: 'left', // 偏移
      //     isSlot: true, // 是否使用插槽（具名插槽-名字为 prop的值）
      //   }
      // ]
    },
    dataList: {
      // 列表数据
      type: Array,
      default: () => [],
    },
    option: {
      // 列表配置-权限、搜索框等等
      type: Object,
      default: () => {},

      // {
      //   isAdd: true, //添加 事件 @add-change
      //   isDel: true, //删除 @del-change
      //   isEdit: true, // 编辑 @edit-change
      //   isSearch: true, // 搜索 @search-change
      //   isRefresh: true, // 刷新 @search-change
      //   pageSize: 10,
      //   searchList: [{
      //     label: '被保险人',
      //     prop: 'insuredName',
      //     formType: 'input',
      //     maxLength: 36,
      //     clearable: true,
      //     placeholder: "请输入被保险人"
      //   }, {
      //     label: '案件状态',
      //     prop: 'caseStatus',
      //     formType: 'select',
      //     placeholder: "请输入被保险人",
      //     selectList: [
      //       { value: '', text: '全部' },
      //       { value: '-1', text: '中止' },
      //       { value: '0', text: '待验标' },
      //       { value: '1', text: '待定损' },
      //       { value: '2', text: '待索赔' },
      //       { value: '3', text: '待结案' },
      //       { value: '4', text: '待评价' },
      //       { value: '5', text: '已评价' },
      //     ]
      //   }, {
      //     label: '报案时间',
      //     formType: 'daterange',
      //     clearable: true,
      //     prop: ['caseBegin', 'caseEnd']
      //   }]
      // }
    },
    totalNum: {
      // 总条数
      type: Number,
      default: 0,
    },
    tableName: {
      type: String,
      default: "",
    },
  },
  watch: {
    column: {
      handler(v) {
        this.initDiyRespList();
      },
      immediate: false,
    },
    dataList: {
      handler(v) {
        this.$refs.tableBox.doLayout()
      },
      deep: true,
    }
  },
  computed: {
    columnList() {
      // isPermissions  权限
      // isShow  默认显示
      // this.columnData = this.column.filter(
      //   (val) => val.isPermissions !== false
      // );
      // return this.column.filter(
      //   (val) => val.isShow && val.isPermissions !== false
      // );
      this.isChangeColumn = false;
      this.$nextTick(() => {
        this.isChangeColumn = true;
      });
      return this.columnData.filter((val) => val.isShow);
    },
    ...mapState(["diyRespList"]),
  },
  data() {
    return {
      //搜索数据
      searchData: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
        search0: "",
        search1: "",
        search2: "",
        search3: "",
        search4: "",
        search5: "",
        search6: "",
        search7: "",
        search8: "",
        search9: "",
        search10: "",
        search11: "",
        search12: "",
        search13: "",
        search14: "",
        search15: "",
        search16: "",
        search17: "",
        search18: "",
        search19: "",
        search20: "",
        search21: "",
        search22: "",
        search23: "",
        search24: "",
      },
      searcType: "top",
      pageSize: 10,
      regionCity: "",
      regionProvince: "",
      columnData: [],
      //多选数组
      tableSelectionList: [],
      activeNames: ["1"],
      oldSearchData: {},
      isChangeColumn: true,
      changeType: null,
      changeView: false,
    };
  },
  async created() {
    await this.initDiyRespList();
    this.$nextTick(() => {
      this.option.searchList
        .filter((val) => val.isPermissions !== false && val.isShow !== false)
        .map((val, index) => {
          this.searchData["search" + index] = val.propValue || "";
        });
      if (this.option.pageSize) {
        this.pageSize = this.option.pageSize || 10;
        this.searchData.pageSize = this.option.pageSize || 10;
      }
      if (this.option.isFirst !== false) {
        this.getList("initialization");
      }
    });
  },
  activated() {
    this.$nextTick(() => {
      if (
        JSON.stringify(this.oldSearchData) != "{}" &&
        this.option.isActivatedFirst !== false
      ) {
        this.getList(this.changeType || "refresh");
      }
    });
  },
  methods: {
    //重置
    onReset(e) {
      Object.keys(this.searchData).forEach((key) => {
        if (key == "pageSize") {
          this.searchData[key] = this.pageSize;
        } else if (key == "pageNum") {
          this.searchData[key] = 1;
        } else if (key.indexOf("search") === -1) {
          this.searchData[key] = "";
        }
      });
      this.option.searchList
        .filter((val) => val.isPermissions !== false && val.isShow !== false)
        .map((val, index) => {
          this.searchData["search" + index] = val.propValue || "";
        });
      this.regionProvince = "";
      this.regionCity = "";
      this.getList("reset");
    },
    //查询
    async queryData(searcType) {
      this.searchData.pageNum = 1;
      this.searcType = searcType;
      this.getList(searcType == "like" ? "fuzzy" : "screening");
    },
    //排序
    sortChange(e) {
      this.searchData.pageNum = 1;
      this.searchData.orderByColumn = e.prop;
      if (e.order === "ascending") {
        this.searchData.isAsc = "asc";
      } else {
        this.searchData.isAsc = "desc";
      }
      this.getList("sort");
    },
    //获取数据
    async getList(isReset) {
      if (isReset == "refresh") {
        this.$emit(
          "search-change",
          this.oldSearchData,
          this.searchData.pageNum,
          this.searchData.pageSize,
          this.searcType,
          isReset
        );
      } else {
        let obj = {
          pageNum: this.searchData.pageNum,
          pageSize: this.searchData.pageSize,
          regionCity: this.regionCity,
          regionProvince: this.regionProvince,
          keyword: this.searchData.keyword,
          fastLike: this.searchData.keyword,
          [this.option.keyword || "noKeyword"]: this.searchData.keyword,
        };
        this.option.searchList
          .filter((val) => val.isPermissions !== false && val.isShow !== false)
          .map((val, index) => {
            if (
              this.searchData["search" + index] &&
              (val.formType == "daterange" ||
                val.formType == "datetimerange") &&
              this.searchData["search" + index].length
            ) {
              if (val.dateFormat) {
                obj[val.prop[0]] = dayjs(
                  this.searchData["search" + index][0]
                ).format("YYYY-MM-DD 00:00:00");
                obj[val.prop[1]] = dayjs(
                  this.searchData["search" + index][1]
                ).format("YYYY-MM-DD 23:59:59");
              } else {
                obj[val.prop[0]] = this.searchData["search" + index][0];
                obj[val.prop[1]] = this.searchData["search" + index][1];
              }
            } else if (
              this.searchData["search" + index] ||
              this.searchData["search" + index] === false
            ) {
              if (val.formType == "select" && val.returnText) {
                obj[val.prop] =
                  val.selectList.find((item) => {
                    return item.value == this.searchData["search" + index];
                  })?.text || "";
              } else {
                obj[val.prop] = this.searchData["search" + index];
              }
            }
          });
        this.oldSearchData = JSON.parse(JSON.stringify(obj));
        this.changeType = null;
        this.$emit(
          "search-change",
          obj,
          this.searchData.pageNum,
          this.searchData.pageSize,
          this.searcType,
          isReset
        );
      }
    },
    // 页数变化
    handleSizeChange(e) {
      this.searchData.pageSize = e;
      this.searchData.pageNum = 1;
      this.getList("changeSize");
    },
    // 页码变化
    handleCurrentChange(e) {
      this.searchData.pageNum = e;
      this.getList("changeNum");
    },
    // 表格选择变化
    handleSelectionChange(e) {
      this.tableSelectionList = e;
    },
    handleEmitChange(type, data) {
      this.$emit(type, data);
    },
    handleChangeView(e) {
      this.changeView = !this.changeView;
    },
    //导出
    handleExportEmit(type, data) {
      let obj = {
        pageNum: this.searchData.pageNum,
        pageSize: this.searchData.pageSize,
        regionCity: this.regionCity,
        regionProvince: this.regionProvince,
        keyword: this.searchData.keyword,
        fastLike: this.searchData.keyword,
      };
      this.option.searchList
        .filter((val) => val.isPermissions !== false && val.isShow !== false)
        .map((val, index) => {
          if (
            this.searchData["search" + index] &&
            val.formType == "daterange" &&
            this.searchData["search" + index].length
          ) {
            if (val.dateFormat) {
              obj[val.prop[0]] = dayjs(
                this.searchData["search" + index][0]
              ).format("YYYY-MM-DD 00:00:00");
              obj[val.prop[1]] = dayjs(
                this.searchData["search" + index][1]
              ).format("YYYY-MM-DD 23:59:59");
            } else {
              obj[val.prop[0]] = this.searchData["search" + index][0];
              obj[val.prop[1]] = this.searchData["search" + index][1];
            }
          } else if (
            this.searchData["search" + index] ||
            this.searchData["search" + index] === false
          ) {
            if (val.formType == "select" && val.returnText) {
              obj[val.prop] =
                val.selectList.find((item) => {
                  return item.value == this.searchData["search" + index];
                })?.text || "";
            } else {
              obj[val.prop] = this.searchData["search" + index];
            }
          }
        });
      this.$emit(
        type,
        data,
        obj,
        this.searchData.pageNum,
        this.searchData.pageSize
      );
    },
    onSelected(e) {
      // console.log(e, this.regionCity, this.regionProvince);
      this.regionProvince = e.province.value || "";
      this.regionCity = e.city.value || "";
    },
    //删除理赔案件
    deleteCase(data) {
      let ids = "";
      this.$confirm("确认删除案件？").then((res) => {
        this.handleEmitChange("del-change", this.tableSelectionList);
        // this.getList();
      });
    },
    onExport() {
      // policyExport(this.formInline).then(res=>{
      //   console.log(res);
      // }).catch(err=>{
      //   console.log(err);
      // })
    },
    // 触发导入
    handleImportClick() {
      this.$emit("import-click");
    },
    // 跳页面
    handleGoPath(path) {
      // console.log(path);
      this.$router.push({
        path: path,
      });
    },
    //  列表控制组件冒泡
    handleBubbling(e) {
      let { index, key, type } = e.target.dataset;
      if (
        type == "top" &&
        this.columnData[Number(index) - 1].type !== "index" &&
        this.columnData[Number(index) - 1].prop !== "operate" &&
        !this.columnData[Number(index) - 1].isNoun
      ) {
        this.columnData.splice(
          Number(index) - 1,
          1,
          ...this.columnData.splice(
            Number(index),
            1,
            this.columnData[Number(index) - 1]
          )
        );
        // this.columnList.splice(Number(index) -1,1,...this.columnList.splice(Number(index), 1 , this.columnList[Number(index) -1]));
      } else if (
        type == "dowm" &&
        this.columnData[Number(index) + 1].type !== "index" &&
        this.columnData[Number(index) + 1].prop !== "operate" &&
        !this.columnData[Number(index) + 1].isNoun
      ) {
        this.columnData.splice(
          Number(index) + 1,
          1,
          ...this.columnData.splice(
            Number(index),
            1,
            this.columnData[Number(index) + 1]
          )
        );
        // this.columnList.splice(Number(index) +1,1,...this.columnList.splice(Number(index), 1 , this.columnList[Number(index) +1]));
      } else if (type) {
        this.$message.error("该项不能操作");
      }
      this.$forceUpdate();
    },
    handleRestoreColumnData() {
      this.columnData = JSON.parse(JSON.stringify(this.column)).filter(
        (val) => val.isPermissions !== false
      );
      this.$nextTick(() => {
        this.updateDiyRespList();
      });
    },
    updateDiyRespList() {
      let id = this.diyRespList.obj[this.tableName]
        ? this.diyRespList.list[this.diyRespList.obj[this.tableName].index].id
        : "";
      updateTitleDiy({
        id: id,
        tableKey: this.tableName,
        tableValue: JSON.stringify(this.columnData),
      }).then((res) => {
        let diyRespList = this.diyRespList.list || [];
        if (id) {
          diyRespList[this.diyRespList.obj[this.tableName].index].tableValue =
            JSON.stringify(this.columnData);
        } else {
          diyRespList.push({
            id: res.data,
            tableKey: this.tableName,
            tableValue: JSON.stringify(this.columnData),
          });
        }
        this.SetDiyRespList(diyRespList);
        // this.$refs.popoverbox.doClose();
        this.$message.success(res.msg || "保存成功！");
      });
    },
    async initDiyRespList() {
      const diyRespList =
        this.tableName && this.diyRespList.obj[this.tableName]
          ? this.diyRespList.obj[this.tableName].value
          : "";
      let columnData = this.column.filter((val) => val.isPermissions !== false);
      // diyRespList ? columnData = diyRespList.filter(val=> columnData.find(item=> item.prop == val.prop)) : '';
      if (diyRespList) {
        let newColumnData = diyRespList.filter((val) =>
          columnData.find((item) => item.prop == val.prop)
        );
        let propList = diyRespList.map((val) => val.prop);
        // let index = columnData.filter(val=> !cc.includes(val.prop));
        let columnIndex = columnData.findIndex(
          (val) => !propList.includes(val.prop)
        );
        if (columnIndex > -1) {
          await this.circulationAdd(columnData, propList, newColumnData);
          // newColumnData.splice(columnIndex, 1, columnData[columnIndex]);
          columnData = newColumnData;
        } else {
          columnData = newColumnData;
        }
      }
      this.columnData = columnData.map((val) => {
        let currentData = this.column.find((item) => item.prop == val.prop);
        if (currentData) {
          val = { ...val, ...currentData, isShow: val.isShow };
        }
        return val;
      });
    },
    circulationAdd(columnData, propList, newColumnData) {
      new Promise(async (reslove, reject) => {
        let columnIndex = columnData.findIndex(
          (val) => !propList.includes(val.prop)
        );
        if (columnIndex > -1) {
          newColumnData.splice(columnIndex, 0, columnData[columnIndex]);
          propList.push(columnData[columnIndex].prop);
          await this.circulationAdd(columnData, propList, newColumnData);
        } else {
          reslove();
        }
      });
    },
    setSearchValue(key, value) {
      let list = this.option.searchList.filter(
        (val) => val.isPermissions !== false && val.isShow !== false
      );
      let ind = list.findIndex((val) => val.prop === key);
      if (ind > -1) {
        this.searchData["search" + ind] = value || "";
        this.changeType = "screening";
      }
    },
    ...mapMutations(["SetDiyRespList"]),
  },
};
</script>

<style lang="scss" scoped>
.caret-c {
  cursor: pointer;
}
.caret-c-d {
  cursor: not-allowed;
  color: #ccc;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  ::v-deep .el-button {
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    border-radius: 4px;
  }
  ::v-deep .el-button--text {
    padding: 0;
  }

  ::v-deep .el-collapse-item__header {
    display: none;
  }
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0;
  }
  ::v-deep .el-collapse-item__wrap {
    background-color: transparent;
  }
  .policy-form-box {
    display: column;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  // padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
  .is-scrolling-left ~ .el-table__fixed-right,
  .is-scrolling-middle ~ .el-table__fixed-right {
    .el-table__fixed-header-wrapper {
      border-left: 2px solid #d6d6d657;
    }
  }
  .is-scrolling-right ~ .el-table__fixed,
  .is-scrolling-middle ~ .el-table__fixed {
    .el-table__fixed-header-wrapper {
      border-right: 2px solid #d6d6d657;
    }
  }

}
.distpicker-address-wrapper select {
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
</style>
